import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ClarityModule} from '@clr/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {BASE_PATH} from '../generated/index';
import {Environment} from "./environment";
import {Configuration} from "../generated/configuration";
import {SharedModule} from "./shared";
import {ClickOutsideModule} from "ng-click-outside";
import {SwiperModule} from "swiper/angular";

import {RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';

// needed to avoid the TS compilation error.
// This can be placed at the top of your app.module file.
declare var js: any;

// AoT requires an exported function for factories
export function apiConfig() {
    return new Configuration({
        basePath: js.environment.apiUrl
    });
}

export function environment() {
    return js.environment;
}

export function basePath(environment: Environment) {
    return environment.apiUrl;
}

export function recaptchaSettings(environment: Environment) {
    return {
        siteKey: environment.recaptchaSiteKey,
    } as RecaptchaSettings;
}

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ClickOutsideModule,
        SharedModule,
        AppRoutingModule,
        ClarityModule,
        SwiperModule,
        RecaptchaModule,
        BrowserAnimationsModule
    ],
    providers: [
        {
            provide: Environment,
            useFactory: environment
        },
        {
            provide: BASE_PATH,
            deps: [Environment],
            useFactory: basePath
        },
        {
            provide: RECAPTCHA_SETTINGS,
            deps: [Environment],
            useFactory: recaptchaSettings
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
