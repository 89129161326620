import {Injectable} from '@angular/core';

@Injectable()
export class Environment {
    production: boolean = false;

    // urls
    apiUrl: string = "http://tbd";

    // console
    consoleUrl: string = 'http://tbd';

    platformVersion: string = "unknown";

    recaptchaSiteKey: string = "6LeAV8UgAAAAAAZs8qG51GT_5f6LDZ9qet8XHvIN";
}
